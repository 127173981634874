<template>
  <div class="allTitleWrap">
    <div class="container">
      <div class="filter">
        <div>
          <span style="margin-right: 10px">时间范围</span>
          <!-- <el-select class="selectClass" v-model="duration">
            <el-option
              :key="item.value"
              v-for="item in durationList"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select> -->
          <!-- <el-cascader
            size="mini"
            v-model="duration"
            :options="durationList"
            @change="handleChange"
            :props="props"
          ></el-cascader> -->
          <!-- <el-cascader
            size="mini"
            :show-all-levels="false"
            v-model="duration"
            :options="durationList"
            @change="handleChange"
          ></el-cascader> -->
          <el-date-picker
            v-model="duration"
            type="monthrange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="YYYY-MM"
            :disabled-date="disabledDate"
            :clearable="false"
            size="small"
          >
          </el-date-picker>
        </div>
        <div v-if="selBrandFlag">
          <span style="margin-left: 40px; margin-right: 10px">选择品牌*</span>
          <el-select
            class="selectBrandClass"
            filterable
            collapse-tags
            multiple
            v-model="selBrand"
          >
            <el-option
              :key="item.label"
              v-for="item in chooseBrandList"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span style="margin-left: 61px; margin-right: 10px"
            >查找{{ searchText }}</span
          >
          <el-input
            @keyup.enter="productSearch"
            class="searchClass"
            v-model="inputBrand"
            :placeholder="searchTextCom"
          ></el-input>
        </div>

        <span
            class="filterClass"
            @click="showPriceFilter"
            style="margin-left: 60px; cursor: pointer"
        >
            {{ isShowPriceFilterText }}
            <i v-if="!isShowPriceFilter" class="el-icon-arrow-down"></i>
            <i v-else class="el-icon-arrow-up"></i>
          </span>
      </div>

      <el-button size="mini" @click="newBrand" class="newBrand">
        新增{{ searchText }}
        <i class="el-icon-plus" style="margin-left: 5px"></i>
      </el-button>
    </div>
    <el-dialog title="提示" v-model="dialogVisible" width="30%">
      <span
        >如果您正在关注的{{
          searchText
        }}暂时不在榜单内，请联系邮件地址：omnivoice@meritco-group.com以添加您需要新增的{{
          searchText
        }}</span
      >
      <template #footer>
        <div>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getCateTree } from "@/api/sov/sovProduct";
import { getSovBrandAggMonth } from "@/api/sov/sovMain";
import { getProductMonth, getSovAggMonth } from "@/api/sov/sovProduct";
import storage from "@/utils/storage";
import { mapState } from "vuex";
export default {
  data() {
    return {
      duration: "",
      selBrand: [],
      inputBrand: "",
      chooseBrandList: [],
      brandListTableLoading: false,
      durationList: [
        {
          value: "1mon",
          label: "近1个月",
        },
        {
          value: "3mon",
          label: "近3个月",
        },
        {
          value: "6mon",
          label: "近6个月",
        },
        {
          value: "12mon",
          label: "近12个月",
        },
        {
          value: "thisYear",
          label: "今年至今",
        },
        {
          value: "lastYear",
          label: "去年全年",
        },
      ],
      dialogVisible: false,
      isShowPriceFilter: false,
      isShowPriceFilterText: "筛选",
      cateOptions: [],
      cateValue: "",
      timeRangeArr: [],
    };
  },

  props: {
    selBrandFlag: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["sites"]),
    searchTextCom() {
      return `请输入${this.searchText}名称`;
    },
  },
  async mounted() {
    this.getProductMonth();
    await this.getMonthFunc();
    this.getCateTree();
    this.lazyLoad();
    this.timeRangeArr = storage.getItem("productTimeRange");
  },
  methods: {
    async getMonthFunc() {
      let res = await getSovAggMonth();
      console.log(res);
      if (res.code === 0) {
        // this.timeRange = res.data;
        storage.setItem("productTimeRange", res.data);
      }
    },
    disabledDate(time) {
      if (this.timeRangeArr) {
        if (this.timeRangeArr.length > 1) {
          return (
            time.getTime() < new Date(this.timeRangeArr[0]).getTime() ||
            time.getTime() >
              new Date(
                this.timeRangeArr[this.timeRangeArr.length - 1]
              ).getTime()
          );
        } else {
          return true;
        }
      }
    },
    async lazyLoad() {
      console.log("hahahaha");
      let res = await getSovBrandAggMonth();
      console.log(res);
      if (res.code === 0) {
        let temp = [];
        res.data.forEach((v) => {
          temp.push({
            value: v,
            label: v,
          });
        });
        this.durationList = [
          {
            value: "zhiding",
            label: "指定月",
            children: temp,
          },
          {
            value: "1mon",
            label: "近1个月",
          },
          {
            value: "3mon",
            label: "近3个月",
          },
          {
            value: "6mon",
            label: "近6个月",
          },
          {
            value: "12mon",
            label: "近12个月",
          },
          {
            value: "thisYear",
            label: "今年至今",
          },
          {
            value: "lastYear",
            label: "去年全年",
          },
        ];
      }
    },
    async getProductMonth() {
      let res = await getProductMonth();
      console.log(res);
      if (res.code === 0) {
        this.duration = [res.data, res.data];
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    async getCateTree() {
      let res = await getCateTree();
      if (res.code === 0) {
        let temp = JSON.stringify(res.data)
          .replace(/tagId/g, "value")
          .replace(/tagName/g, "label");
        this.cateOptions = JSON.parse(temp);
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    showPriceFilter() {
      this.isShowPriceFilter = !this.isShowPriceFilter;
      this.isShowPriceFilterText =
        this.isShowPriceFilterText === "筛选" ? "收起" : "筛选";
      this.$emit("showPriceFilter", true);
    },
    newBrand() {
      this.dialogVisible = true;
    },
    productSearch() {
      console.log(this.inputBrand);
      this.$emit("productSearch", this.inputBrand);
    },
  },
  watch: {
    duration(val) {
      console.log(val, "12312");

      if (val[0] && val[0] === "zhiding") {
        this.$emit("changeDuration", val, val[1]);
      } else {
        this.$emit("changeDuration", val);
      }
    },
    // cateValue(val) {
    //   console.log(val);
    //   this.productListId = [];
    //   this.productList = [];
    //   if (val.length === 0) {
    //     this.cateVal = "";
    //   } else {
    //     this.cateVal = val[val.length - 1];
    //   }
    //   this.page = 1;
    //   this.currentPage = this.page;
    // },
  },
};
</script>

<style lang="scss" scoped>
.allTitleWrap {
  .container {
    font-size: 16px;
    background-color: #fff;
    height: 62px;
    line-height: 60px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 0 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter {
      width: 75%;
      display: flex;
      align-items: center;
    }

    .newBrand {
      color: #fff;
      width: 92px;
      padding: 0;
      background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
      border-radius: 4px;
    }
  }
  .priceFilter {
    // display: none;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ebebeb;
    margin-top: 10px;
    border-radius: 10px;
  }
  .filterClass {
    font-size: 15px;
    &:hover {
      color: #4d7ad7;
    }
  }
}
</style>

<style lang="scss">
.allTitleWrap {
  .selectBrandClass,
  .selectClass {
    .el-scrollbar__view.el-select-dropdown__list {
      margin-top: 5px;
    }

    .el-select__caret.el-input__icon.el-icon-arrow-up {
      line-height: 28px;
    }

    font-size: 14px;

    .el-input__inner {
      width: 122px;
      height: 28px;
    }

    .el-input--suffix {
      height: 35px;
      line-height: 35px;
    }
  }

  .selectBrandClass {
    .el-input__inner {
      width: 260px;
      height: 28px;
    }
  }

  .searchClass {
    line-height: 28px;
    font-size: 14px;
    width: 200px!important;

    .el-input__inner {
      width: 200px;
      height: 28px;
    }

    .el-input--suffix {
      height: 35px;
      line-height: 35px;
    }
  }
  .filter .el-date-editor .el-range-separator {
    width: 9%;
  }
}
.el-cascader__dropdown.el-popper.is-light.is-pure {
  max-width: 550px !important;
}
</style>
